.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
}
.yt-lite.lyt-activated::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: var(--aspect-ratio);
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.yt-lite > .lty-playbtn {
  width: 70px;
  height: 46px;
  background-color: #212121;
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
  background-color: red;
  opacity: 1;
}
.yt-lite > .lty-playbtn::before {
  content: "";
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 11px 0 11px 19px;
}
.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

@font-face {
font-family: '__euclid_7ede16';
src: url(/_next/static/media/ab5669bb1357b10f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__euclid_7ede16';
src: url(/_next/static/media/9db5af23693e1365-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__euclid_7ede16';
src: url(/_next/static/media/3b077a606c16050c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__euclid_Fallback_7ede16';src: local("Arial");ascent-override: 93.26%;descent-override: 24.99%;line-gap-override: 0.00%;size-adjust: 107.23%
}.__className_7ede16 {font-family: '__euclid_7ede16', '__euclid_Fallback_7ede16'
}

